<template>
  <div>
    <el-dialog
      title="单词详情"
      :visible.sync="show"
      width="70%"
      :before-close="closeFn"
    >
      <el-card>
        <el-form
          label-width="110px"
          :model="editWordDetailForm"
          ref="editWordDetailFormRef"
          :rules="editWordDetailFormRules"
          :inline="true"
        >
          <!-- 单词 -->
          <el-row>
            <el-form-item label="单词:" prop="spell">
              <el-input
                v-model="editWordDetailForm.spell"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="中文释义:" prop="chinesemeaning">
              <el-input
                v-model="editWordDetailForm.chinesemeaning"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="音频:">
              <el-upload
                :action="uploadAudioResults.uploadUrl"
                ref="uploadAudioResultsRef"
                :data="{
                  filePath: 'audio/ANALOGFILE/round2/2023/',
                }"
                :headers="uploadAudioResults.headerObj"
                :on-success="uploadAudioResultsSuccess.bind(null, {'fileTyle' : 'purl'})"
                :on-error="importChineseWordExcelError"
                :limit="1"
                :on-change="onChangeLoad"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >上传</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  style="margin-left: 10px"
                  @click="downLoadAudio(editWordDetailForm.purl)"
                  >下载</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  @click="playAudio(editWordDetailForm.purl)"
                  >播放</el-button
                >
              </el-upload>
            </el-form-item>
          </el-row>
          <!-- 定义 -->
          <el-row>
            <el-form-item label="定义:" prop="def">
              <el-input
                v-model="editWordDetailForm.def"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="中文定义:" prop="chinesedef">
              <el-input
                v-model="editWordDetailForm.chinesedef"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="音频:">
              <el-upload
                :action="uploadAudioResults.uploadUrl"
                ref="uploadAudioResultsRef"
                :data="{
                  filePath: 'audio/ANALOGFILE/round2/2023/',
                }"
                :headers="uploadAudioResults.headerObj"
                :on-success="uploadAudioResultsSuccess.bind(null, {'fileTyle' : 'durl'})"
                :on-error="importChineseWordExcelError"
                :limit="1"
                :on-change="onChangeLoad"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >上传</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  style="margin-left: 10px"
                  @click="downLoadAudio(editWordDetailForm.durl)"
                  >下载</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  @click="playAudio(editWordDetailForm.durl)"
                  >播放</el-button
                >
              </el-upload>
            </el-form-item>
          </el-row>
          <!-- 例句 -->
          <el-row>
            <el-form-item label="例句:" prop="exp">
              <el-input
                v-model="editWordDetailForm.exp"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="中文例句:" prop="chineseexp">
              <el-input
                v-model="editWordDetailForm.chineseexp"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="音频:">
              <el-upload
                :action="uploadAudioResults.uploadUrl"
                ref="uploadAudioResultsRef"
                :data="{
                  filePath: 'audio/ANALOGFILE/round2/2023/',
                }"
                :headers="uploadAudioResults.headerObj"
                :on-success="uploadAudioResultsSuccess.bind(null, {'fileTyle' : 'eurl'})"
                :on-error="importChineseWordExcelError"
                :limit="1"
                :on-change="onChangeLoad"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >上传</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  style="margin-left: 10px"
                  @click="downLoadAudio(editWordDetailForm.eurl)"
                  >下载</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  @click="playAudio(editWordDetailForm.eurl)"
                  >播放</el-button
                >
              </el-upload>
            </el-form-item>
          </el-row>
          <el-form-item label="组别:" prop="level">
            <el-select
              placeholder="请选择"
              style="width: 100%"
              v-model="editWordDetailForm.level"
              clearable
            >
              <el-option
                v-for="item in dict_group"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="难度:" prop="difficulty">
            <el-input
              v-model="editWordDetailForm.difficulty"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="国际音标:" prop="internationalPhoneticAlphabet">
            <el-input
              v-model="editWordDetailForm.internationalPhoneticAlphabet"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="托福词汇" prop="istoefl">
            <el-select
              placeholder="请选择"
              v-model="editWordDetailForm.istoefl"
              style="width: 100%"
            >
              <el-option
                v-for="item in dict_istoefl"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="词源:" prop="languageOrigin">
            <el-select
              placeholder="请选择"
              style="width: 100%"
              v-model="editWordDetailForm.languageOrigin"
              clearable
            >
              <el-option
                v-for="item in dict_LanguageOrigin"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <!-- <el-input
              v-model="editWordDetailForm.languageOrigin"
              placeholder="请输入"
              clearable
            ></el-input> -->
          </el-form-item>
          <el-form-item label="辅音长度:" prop="ofConsonantLetters">
            <el-input
              v-model="editWordDetailForm.ofConsonantLetters"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="元音长度:" prop="ofVowelLetters">
            <el-input
              v-model="editWordDetailForm.ofVowelLetters"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="比赛形式:" prop="matchGames">
            <el-input
              v-model="editWordDetailForm.matchGames"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="词性:" prop="partofSpeech">
            <el-input
              v-model="editWordDetailForm.partofSpeech"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="前缀:" prop="prefixes">
            <el-input
              v-model="editWordDetailForm.prefixes"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="后缀:" prop="suffixes">
            <el-input
              v-model="editWordDetailForm.suffixes"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="词根:" prop="roots">
            <el-input
              v-model="editWordDetailForm.roots"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="情景:" prop="scene">
            <el-select
              placeholder="请选择"
              style="width: 100%"
              v-model="editWordDetailForm.scene"
              clearable
            >
              <el-option
                v-for="item in dict_scene"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="来源:" prop="source">
            <el-input
              v-model="editWordDetailForm.source"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="state">
            <el-select
              placeholder="请选择"
              v-model="editWordDetailForm.state"
              style="width: 100%"
            >
              <el-option
                v-for="item in dict_wordStorageType"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="音节" prop="syllables">
            <el-select
              placeholder="请选择"
              v-model="editWordDetailForm.syllables"
              style="width: 100%"
            >
              <el-option
                v-for="item in dict_syllables"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="托福分类:" prop="toefletpo">
            <el-select
              placeholder="请选择"
              style="width: 100%"
              v-model="editWordDetailForm.toefletpo"
              clearable
            >
              <el-option
                v-for="item in dict_toefletpo"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="托福例句:" prop="toeflexp">
            <el-input
              v-model="editWordDetailForm.toeflexp"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="托福热度:" prop="toeflhotstart">
            <el-input
              v-model="editWordDetailForm.toeflhotstart"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="韦氏音标:" prop="websterPhoneticAlphabet">
            <el-input
              v-model="editWordDetailForm.websterPhoneticAlphabet"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <el-row type="flex" justify="center" style="margin-top: 20px">
        <el-col :span="4">
          <el-button type="primary" style="width: 100%" @click="save"
            >保存</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
    <audio ref="audioPlayer" @ended="overAudio"></audio>
  </div>
</template>

<script>
import { saveWordDetail } from '@/http/api'
export default {
  name: 'wordAddEdit',
  props: {
    show: Boolean,
    title: String,
    editWordDetailForm: Object
  },
  data () {
    return {
      AudioIsPlay: false,
      editWordDetailFormRules: {
        spell: [{ required: true, message: '请输入', trigger: 'blur' }],
        def: [{ required: true, message: '请输入', trigger: 'blur' }],
        exp: [{ required: true, message: '请输入', trigger: 'blur' }],
        level: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      dict_syllables: this.$userInfo.dict_syllables(),
      dict_LanguageOrigin: this.$userInfo.dict_LanguageOrigin(this).then(value => { this.dict_LanguageOrigin = value }),
      dict_scene: this.$userInfo.dict_scene(this).then(value => { this.dict_scene = value }),
      dict_toefletpo: this.$userInfo.dict_toefletpo(this).then(value => { this.dict_toefletpo = value }),
      dict_group: this.$userInfo.dict_group(),
      dict_wordStorageType: this.$userInfo.dict_wordStorage(),
      // 上传音频
      uploadAudioResults: {
        uploadUrl: this.$env.baseIP + 'upload/uploadFileAliOss',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      isUploadFile: false, // 是否已选择上传文件
      uploadImgUrl: '',
      dict_istoefl: [
        {
          dictCode: 1,
          dictLabel: '是',
          dictValue: 1
        },
        {
          dictCode: 0,
          dictLabel: '否',
          dictValue: 0
        }
      ],
      form: {
        label: '',
        label2: '',
        label3: ''
      }
    }
  },

  methods: {
    submitFn () {
      this.$emit('submit')
    },
    closeFn () {
      this.$refs.editWordDetailFormRef.resetFields()
      this.$emit('close')
    },
    // 上传文件加载中
    importChineseWordExcelProgress () {
    },
    // 上传文件失败回调
    importChineseWordExcelError (error) {
      console.log('importChineseWordExcelError', error)
      this.$message.error('上次单词文件失败')
    },
    // 监听上传文件变化
    onChangeLoad (files, fileList) {
      if (fileList.length > 0) {
        this.isUploadFile = true
      }
    },
    // 音频文件上传成功
    uploadAudioResultsSuccess (res, datas) {
      this.editWordDetailForm[res.fileTyle] = datas.data
      this.isUploadFile = false
      this.$message.success('上传成功')
      this.$refs.uploadAudioResultsRef.clearFiles()// 清除文件
    },
    // 音频播放
    playAudio (url) {
      this.AudioIsPlay = true
      this.$refs.audioPlayer.src = url
      this.$refs.audioPlayer.play()
    },
    overAudio () {
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.pause()
        this.AudioIsPlay = false
      }
    },
    // 音频下载
    downLoadAudio (url) {
      window.open(url)
    },
    // 音频文件上传超时
    uploadAudioResultsExceed (files, fileList) {
      console.log('fileList', files, fileList)
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.uploadAudioResultsRef.clearFiles()// 清除文件
      this.$refs.uploadAudioResultsRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    uploadAudioResultsBeforeUpload () {
      return new Promise((resolve, reject) => {
        if (this.matchInfo.teamMatchInfo.length > 0) {
          this.$confirm('确认替换当前音频文件么?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            resolve()
          }).catch(() => {
            this.isUploadFile = false
            reject(new Error('error'))
          })
        } else {
          resolve()
        }
      })
    },
    labelFormatter (data) {
      switch (data) {
        case 0:
          return '英文词性'
        case 1:
          return '中文词性'
        default:
          return '未知词性'
      }
    },
    save () {
      this.$refs.editWordDetailFormRef.validate(async valid => {
        if (!valid) return
        saveWordDetail(this.editWordDetailForm).then((res) => {
          if (res.code === 200) {
            this.$message.success('保存成功')
            this.closeFn()
          }
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.voiceStrClass {
  font-size: 16px;
}
.changeVoiceBack {
  display: flex;
  align-items: center;
}
.playVoiceImageClass {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.wordImg {
  widows: 100px;
  height: 100px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
</style>
